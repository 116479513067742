import React from "react";

export const GithubMark = () => (
  <svg width="98" height="96" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M48.854 0C21.839 0 0 22 0 49.217c0 21.756 13.993 40.172 33.405 46.69 2.427.49 3.316-1.059 3.316-2.362 0-1.141-.08-5.052-.08-9.127-13.59 2.934-16.42-5.867-16.42-5.867-2.184-5.704-5.42-7.17-5.42-7.17-4.448-3.015.324-3.015.324-3.015 4.934.326 7.523 5.052 7.523 5.052 4.367 7.496 11.404 5.378 14.235 4.074.404-3.178 1.699-5.378 3.074-6.6-10.839-1.141-22.243-5.378-22.243-24.283 0-5.378 1.94-9.778 5.014-13.2-.485-1.222-2.184-6.275.486-13.038 0 0 4.125-1.304 13.426 5.052a46.97 46.97 0 0 1 12.214-1.63c4.125 0 8.33.571 12.213 1.63 9.302-6.356 13.427-5.052 13.427-5.052 2.67 6.763.97 11.816.485 13.038 3.155 3.422 5.015 7.822 5.015 13.2 0 18.905-11.404 23.06-22.324 24.283 1.78 1.548 3.316 4.481 3.316 9.126 0 6.6-.08 11.897-.08 13.526 0 1.304.89 2.853 3.316 2.364 19.412-6.52 33.405-24.935 33.405-46.691C97.707 22 75.788 0 48.854 0z"
      className="fill-text dark:fill-textDark"
    />
  </svg>
);

export const LinkedinMark = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
    viewBox="0 0 128 128"
    className="w-28 h-28 fill-text dark:fill-textDark"
  >
    <path d="M96,16H32c-8.836,0-16,7.164-16,16v64c0,8.836,7.164,16,16,16h64c8.836,0,16-7.164,16-16V32C112,23.164,104.836,16,96,16z M48,94h-10V54h10 V94z M48.058,46.018C46.764,47.338,45.076,48,43,48c-2.052,0-3.73-0.682-5.038-2.046S36,42.916,36,41.936 c0-2.04,0.654-3.704,1.962-4.996C39.27,34.646,41.948,34,43,34c2.076,0,3.764,0.646,5.058,1.938 C49.352,37.23,50,38.896,50,40.936C50,43.02,49.352,44.7,48.058,46.018z M94,94h-10V71.774C84,65.576,80.428,62,76,62 c-2.134,0-4.548,1.296-5.93,2.938S68,68.662,68,71.188V94h-10V54h10v6.328h0.156c2.944-4.87,7.226-7.288,12.852-7.304 C89,52.5,94,58.5,94,69.508V94z" />
  </svg>
);
